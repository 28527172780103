import dayjs from 'dayjs'
import {
  ENGAGEMENT_DATE_FORMATS,
} from '@/const/analytics'

/**
 * Transform Performance to show in DataWell
 * @param {Array} engagementData
 * @param {String} interval ['day', 'week', 'month']
 * @return {Array} Engagement Metrics
 */
export const transformEngagementForApexCharts = (engagementData = [], interval = 'day') => {
  const engagement = {
    labels: [],
    values: [],
  }

  const engagementEventData = {
    sent_emails: [],
    delivered_emails: [],
    dropped_emails: [],
    bounced_emails: [],
    unsubscribed: [],
    spam_reported: [],
    total_opens: [],
    unique_opens: [],
    unique_opens_amp: [],
    unique_opens_html: [],
    total_clicks: [],
    unique_clicks: [],
    unique_clicks_amp: [],
    unique_clicks_html: [],
    yamps: [],
    unique_yamps: [],
    delivery_rate: [],
    bounce_rate: [],
    open_rate: [],
    // open_rate_amp: [],
    // open_rate_html: [],
    click_rate: [],
    click_rate_amp: [],
    click_rate_html: [],
    yamp_rate: [],
  }

  engagementData.forEach((item, index) => {
    const { date_range, stats } = item
    let label = dayjs(date_range.start_at).format(ENGAGEMENT_DATE_FORMATS[interval])

    const sentEmails = (stats.total.processed + stats.total.dropped)

    let deliveryRate = 0
    let bounceRate = 0
    let openRate = 0
    // let openRateAmp = 0
    // let openRateHtml = 0

    const clickRate = formatMetricRate(stats.unique.click, stats.unique.open)
    const clickRateAmp = formatMetricRate(stats.unique_content_type.click.amp, stats.unique_content_type.open.amp)
    const clickRateHtml = formatMetricRate(stats.unique_content_type.click.html, stats.unique_content_type.open.html)
    const yampRate = formatMetricRate(stats.unique.yamps, stats.unique.open)

    if (sentEmails) {
      deliveryRate = formatMetricRate(stats.total.delivered, sentEmails)
      bounceRate = formatMetricRate(stats.total.bounce, sentEmails)
      openRate = formatMetricRate(stats.unique.open, stats.total.delivered)
      // openRateAmp = formatMetricRate(stats.unique_content_type.open.amp, stats.total.delivered)
      // openRateHtml = formatMetricRate(stats.unique_content_type.open.html, stats.total.delivered)
    } else if (index > 0) {
      // TODO Get rates from backend
      let referenceIndexDate = -1

      for (let i = index; i >= 0 && (i >= index - 7); i--) {
        if (engagementData[i].stats.total.processed) {
          referenceIndexDate = i
        }
      }

      if (referenceIndexDate > -1) {
        const statsReference = engagementData[referenceIndexDate].stats
        deliveryRate = formatMetricRate(stats.total.delivered, statsReference.total.processed)
        bounceRate = formatMetricRate(stats.total.bounce, statsReference.total.processed)
        openRate = formatMetricRate(stats.unique.open, statsReference.total.delivered)
        // openRateAmp = formatMetricRate(stats.unique_content_type.open.amp, statsReference.total.delivered)
        // openRateHtml = formatMetricRate(stats.unique_content_type.open.html, statsReference.total.delivered)
      }
    }

    // Totals
    engagementEventData.sent_emails.push(sentEmails)
    engagementEventData.delivered_emails.push(stats.total.delivered)
    engagementEventData.dropped_emails.push(stats.total.dropped)
    engagementEventData.bounced_emails.push(stats.total.bounce)
    engagementEventData.unsubscribed.push(stats.total.unsubscribe)
    engagementEventData.spam_reported.push(stats.total.spamreport)

    engagementEventData.total_opens.push(stats.total.open)
    engagementEventData.unique_opens.push(stats.unique.open)
    engagementEventData.unique_opens_amp.push(stats.unique_content_type.open.amp)
    engagementEventData.unique_opens_html.push(stats.unique_content_type.open.html)

    engagementEventData.total_clicks.push(stats.total.click)
    engagementEventData.unique_clicks.push(stats.unique.click)
    engagementEventData.unique_clicks_amp.push(stats.unique_content_type.click.amp)
    engagementEventData.unique_clicks_html.push(stats.unique_content_type.click.html)

    engagementEventData.yamps.push(stats.total.yamps)
    engagementEventData.unique_yamps.push(stats.unique.yamps)

    // Rates
    engagementEventData.delivery_rate.push(deliveryRate)
    engagementEventData.bounce_rate.push(bounceRate)
    engagementEventData.open_rate.push(openRate)
    // engagementEventData.open_rate_amp.push(openRateAmp)
    // engagementEventData.open_rate_html.push(openRateHtml)
    engagementEventData.click_rate.push(clickRate)
    engagementEventData.click_rate_amp.push(clickRateAmp)
    engagementEventData.click_rate_html.push(clickRateHtml)
    engagementEventData.yamp_rate.push(yampRate)

    if (interval === 'week') {
      label += `-${dayjs(date_range.end_at).format(ENGAGEMENT_DATE_FORMATS[interval])}`
    }

    engagement.labels.push(label)
  })

  for (const [eventName, eventValue] of Object.entries(engagementEventData)) {
    const engagementValue = {
      name: eventName,
      type: 'column',
      data: eventValue,
    }

    if (eventName.includes('rate')) {
      engagementValue.type = 'line'
      engagementValue.stackType = '100%'
    }

    engagement.values.push(engagementValue)
  }

  return engagement
}

/**
 * Transform Performance to show in DataWell
 * @param {Object} performanceData
 * @return {Array} Performance Metrics
 */
export const transformPerformanceForDataWell = (performanceData = {}) => {
  const performace = []

  // Sent emails
  performace.push({
    label: 'sent_emails',
    value: (performanceData.total.processed + performanceData.total.dropped),
    extraData: [{
      label: 'delivered_emails',
      value: performanceData.total.delivered || 0
    }, {
      label: 'dropped_emails',
      value: performanceData.total.dropped || 0,
    }, {
      label: 'bounced_emails',
      value: performanceData.total.bounce || 0,
    }, {
      label: 'unsubscribed',
      value: performanceData.total.unsubscribe || 0,
    }, {
      label: 'spam_reported',
      value: performanceData.total.spamreport || 0,
    }]
  })

  // Total opens
  performace.push({
    label: 'total_opens',
    value: performanceData.total.open,
    ...performanceData.content_type.open
  })

  // Unique opens
  performace.push({
    label: 'unique_opens',
    value: performanceData.unique.open,
    ...performanceData.unique_content_type.open
  })

  const openRate = formatMetricRate(performanceData.unique.open, performanceData.total.delivered)
  // const openRateAmp = formatMetricRate(performanceData.unique_content_type.open.amp, performanceData.unique.open)
  // const openRateHtml = formatMetricRate(performanceData.unique_content_type.open.html, performanceData.unique.open)

  // Open Rate
  performace.push({
    label: 'open_rate',
    value: `${openRate}%`,
    // amp: `${openRateAmp}%`,
    // html: `${openRateHtml}%`,
  })

  // Total clicks
  performace.push({
    label: 'total_clicks',
    value: performanceData.total.click,
    ...performanceData.content_type.click
  })

  // Unique clicks
  performace.push({
    label: 'unique_clicks',
    value: performanceData.unique.click,
    ...performanceData.unique_content_type.click
  })

  const clickRate = formatMetricRate(performanceData.unique.click, performanceData.unique.open)
  const clickRateAmp = formatMetricRate(performanceData.unique_content_type.click.amp, performanceData.unique_content_type.open.amp)
  const clickRateHtml = formatMetricRate(performanceData.unique_content_type.click.html, performanceData.unique_content_type.open.html)

  // Click Rate
  performace.push({
    label: 'click_rate',
    value: `${clickRate}%`,
    amp: `${clickRateAmp}%`,
    html: `${clickRateHtml}%`,
  })

  // Yamps
  performace.push({
    label: 'yamps',
    value: performanceData.total.yamps,
    extraData: [{
      label: 'unique_yamps',
      value: performanceData.unique.yamps
    }, {
      label: 'total_yamps',
      value: performanceData.total.yamps
    }]
  })

  const yampRate = formatMetricRate(performanceData.unique.yamps, performanceData.unique_content_type.open.amp)
  const yampAverage = formatMetricRate(performanceData.total.yamps, performanceData.unique_content_type.open.amp)

  // Yamp Rate
  performace.push({
    label: 'yamp_rate',
    value: `${yampRate}%`,
    extraData: [{
      label: 'yamp_average',
      value: `${yampAverage}%`,
    }, {
      label: 'total_yamps',
      value: performanceData.total.yamps
    }]
  })

  return performace
}

function formatMetricRate (valueA, valueB) {
  if (!valueA || !valueB) return valueA
  const rate = valueA / valueB
  return Math.round(rate * 10_000) / 100
}
